import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ProductView from '../views/ProductView.vue';
import NotFound from '../views/404View.vue';
import Maintenance from '../views/Maintenance.vue';
import TermsView from '../views/TermsView.vue';
import ContactView from '../views/ContactView.vue';
import ShopView from '../views/ShopView.vue';
import BlogView from '../views/BlogView.vue';
import FaqView from '../views/FaqView.vue';
import CatalogosView from '../views/CatalogosView.vue';
import CarritoView from '../views/CarritoView.vue';
import ProfileView from '../views/ProfileView.vue';
import LogregView from '../views/LogsigView.vue';
import CotizacionView from '../views/CotizacionView.vue';
import CatalogoSecurityView from '@/views/CatalogoSecurityView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/perfil',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/login',
    name: 'login',
    component: LogregView
  },
  {
    path: '/nosotros',
    name: 'nosotros',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  },
  {
    path: '/tienda/:fparam',
    name: 'tienda',
    props: true,
    component: ShopView
  },
  {
    path: '/tienda',
    name: 'tienda2',
    props: true,
    component: ShopView
  },
  {
    path: '/blog/:title',
    name: 'blog',
    props: true,
    component: BlogView
  },
  {
    path: '/blog',
    name: 'blog1',
    component: BlogView
  },
  {
    path: '/producto/:brand/:model',
    name: 'producto',
    props: true,
    component: ProductView
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: ContactView
  },
  {
    path: '/terminos-y-condiciones',
    name: 'terms',
    component: TermsView
  },
  {
    path: '/catalogos',
    name: 'catalogos',
    component: CatalogosView
  },
  {
    path: '/catalogos-seguridad',
    name: 'catalogos-seguridad',
    component: CatalogoSecurityView
  },
  {
    path: '/carrito',
    name: 'cart',
    component: CarritoView
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView
  },
  {
    path: '/cotizacion/:folio',
    name: 'cotizacion',
    props: true,
    component: CotizacionView
  },
  {
    path: '/mantenimiento',
    name: 'mantenimiento',
    component: Maintenance
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
