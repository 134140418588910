export const ApiService = {
    async getDataProduct(method, api, datas) {
        const headers = new Headers({
            'Content-Type': 'application/json',
            // Agrega otros headers según sea necesario
        });

        const requestOptions = {
            method: method,
            headers: headers,
            mode: 'cors', // Para permitir CORS
        };

        if (datas) {
            requestOptions.body = JSON.stringify(datas);
        }
        try{
            //var res = fetch('https://api.proveedorferretero.net'+api, requestOptions)
            var res = fetch('https://www.api.proveedorferretero.net'+api, requestOptions)
            //var res = fetch('http://187.188.181.242:3334'+api, requestOptions)
            //var res = fetch('http://127.0.0.1:8000'+api, requestOptions)
            //var res = fetch('http://192.168.1.37:5000'+api, requestOptions)
            .then(response => {
                // Verificar si la respuesta tiene el encabezado Content-Type indicando JSON
                const contentType = response.headers.get('Content-Type');
                if (contentType && contentType.includes('application/json')) {
                    return response.json();
                } else {
                    throw new Error('La respuesta no está en formato JSON');
                }
            })
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('Error en la solicitud:', error.message);
            });
            return res;
        } catch(error){
            console.error('Error en la solicitud: ', error.message)
        }
        /*
        var res = fetch('https://www.api.proveedorferretero.net'+api, requestOptions)
        //var res = fetch('http://187.188.181.242:3334'+api, requestOptions)
        //var res = fetch('http://127.0.0.1:8000'+api, requestOptions)
        //var res = fetch('http://192.168.1.37:5000'+api, requestOptions)
            .then(response => {
                // Verificar si la respuesta tiene el encabezado Content-Type indicando JSON
                const contentType = response.headers.get('Content-Type');
                if (contentType && contentType.includes('application/json')) {
                    return response.json();
                } else {
                    throw new Error('La respuesta no está en formato JSON');
                }
            })
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('Error en la solicitud:', error.message);
            });
        */
        
    },
    get(api, datas) {
        return Promise.resolve(this.getDataProduct('GET', api, datas));
    },
    post(api, datas) {
        return Promise.resolve(this.getDataProduct('POST', api, datas));
    },
    update(api, datas) {
        return Promise.resolve(this.getDataProduct('UPDATE', api, datas));
    },
    put(api, datas) {
        return Promise.resolve(this.getDataProduct('PUT', api, datas));
    },
    delete(api, datas) {
        return Promise.resolve(this.getDataProduct('DELETE', api, datas));
    }
}