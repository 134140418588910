<template>
    <BannerBlue title="Catálogos seguridad"/>
    <div class="container">
        <div class="contenedor-externo">
            <div v-for="(imagen, index) in catalogos" :key="index" class="contenedor-interno">
                <div class="title-cat mb-1">
                    <a :href="imagen.pdf" class="img-cat" target="_blank">
                        <h4 style="bottom:5px;">{{ imagen.title }}</h4>
                    </a>
                </div>
                <a :href="imagen.pdf" class="img-cat" target="_blank">
                    <img loading="lazy" :src="imagen.image" :alt="imagen.title" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import BannerBlue from '@/components/BannerBlue.vue';
import { ref } from 'vue';
export default {
    props:{},
    components:{
        BannerBlue,
    },
    data(){
        return{
            layout: ref('grid'),
            catalogosOrdenados: ref([]),
            catalogos:[
                {
                    title: 'LIBUS',
                    image:'https://files.proveedorferretero.net/img/2025/catalogo/libus.webp',
                    pdf:'https://files.proveedorferretero.net/pdf/2025/catalogo-libus-2024.pdf',
                    imp: 10
                },
                {
                    title:'libus protect',
                    image:'https://files.proveedorferretero.net/img/2025/catalogo/protect.webp',
                    pdf:'https://files.proveedorferretero.net/pdf/2025/catalogo-libus-bls-2024-2025.pdf',
                    imp: 10
                },
                {
                    title: 'jyrsa',
                    image:'https://files.proveedorferretero.net/img/2025/catalogo/jyrsa.webp',
                    pdf:'https://files.proveedorferretero.net/pdf/2025/guia-rapida-eco-jyrsa-2024.pdf',
                    imp: 10
                },
                {
                    title:'safe-fit',
                    image:'https://files.proveedorferretero.net/img/2025/catalogo/safe-fit.webp',
                    pdf:'https://files.proveedorferretero.net/pdf/2025/catalogo+safefit+2025.pdf',
                    imp: 10
                },
                {
                    title:'abus',
                    image:'https://files.proveedorferretero.net/img/2025/catalogo/abus-2024.webp',
                    pdf:'https://files.proveedorferretero.net/pdf/2025/abus-2024.pdf',
                    imp: 10
                }
            ]
        };
    },
    mounted: function(){
        this.ordenarCatalogos();
    },
    methods:{
        ordenarCatalogos() {
        // Ordenar primero por "imp" y luego por "title"
        this.catalogos.sort((a, b) => {
            if (a.imp !== b.imp) {
                return a.imp - b.imp;
            } else {
                return a.title.localeCompare(b.title);
            }
        });
        },
    },
}
</script>

<style scoped>
h4{
    line-height: 40px;
}
h4:hover{
    color: #ffbf00;
}
img:hover{
    box-shadow: 0 0 20px #ffbf00;
}
.contenedor-externo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.contenedor-interno {
    margin-right: 10px; /* Espacio entre los contenedores internos, ajusta según tu preferencia */
    max-width: 400px;
}
.title-cat{
    height: 80px;
    display: flex;
    text-align: start;
    align-items: flex-end;
}
.title-cat a h3{
    color: #000;
}
.title-cat img{
    box-shadow: none;
}
.contenedor-interno a img:hover ~ .title-cat a h3{
    color: #ffbf00;
}
.title-cat a h3:hover{
    color: #ffbf00;
}
img {
    width: 100%; /* Asegura que las imágenes ocupen todo el ancho del contenedor interno */
    height: auto; /* Permite que la altura se ajuste proporcionalmente al ancho */
    border-radius: 10px;
}
@media (max-width: 1200px) {
    .img{
        width: 80%;
    }
    .title-cat{
        padding-left: 15px;
    }
}
</style>